import { createGlobalStyle } from 'styled-components';

export const PublicLayoutGlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: ${(props: any) => props.theme.palette.background.default};
  }
`;

export const DashboardLayoutGlobalStyle = createGlobalStyle`
html,
body,
#root {
  height: 100%;
}

body {
  background: ${(props: any) => props.theme.palette.background.default};
}

.MuiCardHeader-action .MuiButtonBase-root {
  padding: 4px;
  width: 28px;
  height: 28px;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.rotate-animation {
  animation: rotation 2s infinite linear;
}

`;
