import { muiPalette, palette } from './palette';
import typography from './typography';
import components from './components';
import breakpoints from './breakpoints';
import shadows from './shadows';
import { createTheme } from '@mui/material';
export const muiTheme = createTheme({
  breakpoints,
  typography,
  shadows,
  components,
  palette: muiPalette,
  spacing: '4px',
});

export const theme = {
  ...muiTheme,
  palette: { ...muiTheme.palette, ...palette },
};
