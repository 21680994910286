import { ThemeOptions } from '@mui/material';
import colors from './colors';
import { rgba } from 'polished';
const components: ThemeOptions['components'] = {
  MuiCardHeader: {
    styleOverrides: {
      action: {
        marginTop: '-4px',
        marginRight: '-4px',
      },
    },
    defaultProps: {
      titleTypographyProps: { variant: 'h6' },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        variants: [
          {
            props: { variant: 'contained' },
            style: {
              backgroundColor: colors.black6,
              color: colors.black0,
              '&:hover': {
                backgroundColor: rgba(colors.black5, 0.5),
              },
            },
          },
          {
            props: { variant: 'outlined' },
            style: {
              borderColor: colors.black0,
              '&:hover': {
                borderColor: colors.black3,
              },
            },
          },
          {
            props: { variant: 'contained', color: 'primary' },
            style: {
              backgroundColor: colors.blue0,
              color: colors.white,
              '&:hover': {
                backgroundColor: rgba(colors.blue0, 0.9),
              },
            },
          },
          {
            props: { variant: 'contained', color: 'error' },
            style: {
              backgroundColor: colors.red,
              color: colors.white,
              '&:hover': {
                backgroundColor: rgba(colors.red, 0.9),
              },
            },
          },
        ],
      },
    },
  },
  MuiIconButton: {
    styleOverrides: {
      root: {
        '&:hover': {
          backgroundColor: rgba(colors.black6, 0.7),
        },
      },
    },
  },
  MuiListItem: {
    styleOverrides: {
      root: {
        '&.Mui-selected': {
          backgroundColor: colors.blue0,
          color: colors.white,
        },
      },
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        marginLeft: 0,
        fontSize: 12,
      },
    },
  },
};

export default components;
