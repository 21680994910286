import React, { FC } from 'react';
import styled from 'styled-components';

import {
  AppBar as MuiAppBar,
  Box,
  Grid,
  IconButton as MuiIconButton,
  Toolbar,
} from '@mui/material';

import { Menu as MenuIcon } from '@mui/icons-material';
import ProjectSelection from '../sidebar/ProjectSelection';
import HeaderAction from './HeaderActions';

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.palette.header.background};
  color: ${(props) => props.theme.palette.header.color};
  box-shadow: ${(props) => props.theme.shadows[1]};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

type THeaderProps = { onDrawerToggle: () => void };
const Header: FC<THeaderProps> = ({ onDrawerToggle }) => {
  return (
    <AppBar position="sticky" elevation={0}>
      <Toolbar>
        <Grid container alignItems="center">
          <Grid item xs>
            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={onDrawerToggle}
                size="large"
              >
                <MenuIcon />
              </IconButton>
            </Box>
          </Grid>
          <Grid item xs={8}>
            <Box display="flex" justifyContent="center">
              <ProjectSelection />
            </Box>
          </Grid>
          <Grid item xs>
            <Box textAlign="right">
              <HeaderAction />
            </Box>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
