import { ThemeOptions } from '@mui/material';
import colors from './colors';

export const muiPalette: ThemeOptions['palette'] = {
  action: {
    hover: colors.black6,
  },
  common: {
    black: colors.black0,
    white: colors.white,
  },
  primary: {
    main: colors.blue0,
    light: colors.blue0,
    dark: colors.blue0,
    contrastText: colors.white,
  },
  secondary: {
    main: colors.red,
    contrastText: colors.white,
  },
  error: {
    main: colors.red,
  },
  warning: {
    main: colors.lightRed,
  },
  info: {
    main: colors.lightGreen,
  },
  success: {
    main: colors.green,
  },
  text: {
    primary: colors.black0,
    secondary: colors.blue0,
    disabled: colors.black3,
  },
  background: {
    default: colors.white,
    paper: colors.white,
  },
};

export const palette = {
  undefined: {
    main: colors.black4,
  },
  maturity: {
    background: colors.white,
    above: colors.lightGreen,
    aboveBar: colors.green,
    moreAbove: colors.green,
    below: colors.lighterRed,
    belowBar: colors.lightRed,
    moreBelow: colors.lightRed,
    actual: colors.blue0,
    neutral: colors.lightGrey,
    expectancy: colors.black0,
    noExpectancy: colors.black5,
  },
  tabs: {
    background: {
      selected: colors.black0,
      unselected: colors.black6,
      outlined: colors.white,
    },
    text: {
      selected: colors.white,
      unselected: colors.black0,
      outlined: colors.black2,
    },
    border: {
      selected: colors.black0,
      unselected: colors.black6,
      outlined: colors.black2,
    },
  },
  header: {
    color: colors.white,
    background: colors.black0,
    search: {
      color: colors.grey,
    },
    indicator: {
      background: colors.blue0,
    },
  },
  sidebar: {
    color: colors.white,
    background: colors.black0,

    header: {
      color: colors.white,
      background: colors.black0,
      brand: {
        color: colors.white,
      },
    },
    footer: {
      color: colors.white,
      background: colors.black0,
      online: {
        background: colors.green,
      },
    },
    section: {
      header: { color: colors.black3 },
      divider: { color: colors.black1 },
    },
    badge: {
      color: colors.white,
      background: colors.blue0,
    },
  },
  body: {
    background: colors.white,
  },
};
