import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import { OneApiProvider } from './ApiContext';
import { OneProvider } from './OneContext';
import { ThemeProvider } from 'styled-components';
import {
  StyledEngineProvider,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import { muiTheme, theme } from '../theme';
import { getConfig } from '../config';
import { AuthorizationProvider } from './AuthorizationContext';
import history from '../utils/history';
import { QueryClientProvider } from '@tanstack/react-query';
import queryClient from '../services/queryClient';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import AuthTokenProvider from './AuthTokenContext';

const onRedirectCallback = (appState: any) => {
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};

export const ContextProviders = ({ children }: React.PropsWithChildren) => {
  return (
    <Auth0Provider
      domain={getConfig('REACT_APP_AUTH0_DOMAIN') as string}
      clientId={getConfig('REACT_APP_AUTH0_CLIENT_ID') as string}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: getConfig('REACT_APP_AUTH0_AUDIENCE') as string,
      }}
      onRedirectCallback={onRedirectCallback}
    >
      <AuthTokenProvider>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools
            initialIsOpen={false}
            position="top-left"
            toggleButtonProps={{ style: { opacity: 0.5 } }}
          />
          <OneApiProvider>
            <StyledEngineProvider injectFirst>
              <MuiThemeProvider theme={muiTheme}>
                <ThemeProvider theme={theme}>
                  <SnackbarProvider maxSnack={3}>{children}</SnackbarProvider>
                </ThemeProvider>
              </MuiThemeProvider>
            </StyledEngineProvider>
          </OneApiProvider>
        </QueryClientProvider>
      </AuthTokenProvider>
    </Auth0Provider>
  );
};

export const ProtectedContextProviders = ({
  children,
}: React.PropsWithChildren) => {
  return (
    <OneProvider>
      <AuthorizationProvider>{children}</AuthorizationProvider>
    </OneProvider>
  );
};
